import AppStore from "../Assets/Appstore.png";
import PlayStore from "../Assets/playstore.png";
import { Link } from "react-router-dom";

const RightSide = () => {
  return (
    <div className="relative w-[100vw] h-[50vh] flex justify-center">
      <div
        className="absolute right-0 bottom-20 w-[70px] h-[30vh] bg-[#773eef] rounded-bl-3xl z-20"
        style={{ clipPath: "polygon(100% 0, 100% 100%, 0 100%)" }}
      ></div>

      <div className="relative bg-white mt-10 z-10 w-[90vw] md:w-[44vw] rounded-3xl h-[43vh] md:h-full block md:hidden items-center pt-5">
        <p className=" poppins-medium text-black font-semibold text-xl pt-10 w-full px-10 items-center text-justify">
          {" "}
          Abhi <span className="font-bold">Whizz App</span> download kerain aur
          nay waqt ke sath grocery ke shopping ka andaz bhi badalye, sahulat our
          aitamad ke sath bemisaal service ka faida uhtaiye
        </p>
        <div className="flex justify-center">
          <Link to="https://apps.apple.com/pk/app/whizzpk/id6670152701">
            <img
              className="mt-2"
              src={AppStore}
              alt=""
              width="140px"
              height="70px"
            />
          </Link>
          <Link to="https://play.google.com/store/apps/details?id=com.whizz.u2ventures">
            <img
              className="mt-2 ms-3"
              src={PlayStore}
              alt=""
              width="140px"
              height="70px"
              style={{
                height: "47px",
              }}
            />
          </Link>
        </div>
      </div>

      {/* <div className="relative h-[47vh] md:h-full mt-4  z-50 w-[90vw] md:w-[44vw] rounded-3xl hidden md:block">
        <img
          src={shop}
          alt="shop"
          className="w-full h-[30vh] md:h-full absolute"
        />
          <img
            src={phone}
            alt="phone"
            className="w-[45%] md:w-[40%] h-[35vh] md:h-full absolute right-0"
          />
      </div> */}

      <div className="relative bg-white mt-6 z-10 w-[90vw] md:w-[44vw] rounded-3xl h-[47vh] md:h-full hidden md:block">
        <p className="poppins-medium text-black font-semibold text-2xl pt-10 w-[60%] md:w-full px-10 items-center text-justify">
          Abhi <span className="font-bold">Whizz App</span> download kerain aur
          nay waqt ke sath grocery ke shopping ka andaz bhi badalye, sahulat our
          aitamad ke sath bemisaal service ka faida uhtaiye
        </p>
        <div className="flex ms-4 mt-5 justify-center">
          <Link to="https://apps.apple.com/pk/app/whizzpk/id6670152701">
            <img
              className="mt-2"
              src={AppStore}
              alt=""
              width="140px"
              height="70px"
            />
          </Link>
          <Link to="https://play.google.com/store/apps/details?id=com.whizz.u2ventures">
            <img
              className="mt-2 ms-3"
              src={PlayStore}
              alt=""
              width="140px"
              height="70px"
              style={{
                height: "47px",
              }}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RightSide;
