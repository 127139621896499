// const SideView = () => {
//   return (
//     <>
//       <div className="relative w-[100vh] h-[50vh]">
//         <div
//           className="absolute top-20 w-[70px] h-[30vh] left-0 bg-[#312f4f] rounded-tr-2xl "
//           style={{ clipPath: "polygon(0 0, 100% 0, 0 100%)" }}
//         ></div>

//         <div className=" w-full flex justify-center items-center text-black text-md">
//           It Starts with grocery!
//         </div>
//       </div>
//     </>
//   );
// };
// export default SideView;
import logo from "../Assets/newlogo.png";
import shop from "../Assets/shop.png";
import phone from "../Assets/phone.png";

const SideView = () => {
  return (
    <div className="relative w-[100vw] h-[50vh] flex justify-center">
      <div
        className="absolute top-20 w-[70px] h-[30vh] left-0 bg-[#773eef] rounded-tr-2xl"
        style={{ clipPath: "polygon(0 0, 100% 0, 0 100%)" }}
      ></div>
      <div className="flex flex-col justify-center items-center">
        <div className="relative mt-8 z-10 md:hidden">
          <div className="flex">
            <img src={logo} alt="Grocery" className="h-[70px] w-[220px]" />
          </div>
          <p className=" text-black font-bold text-sm ms-16">
            It starts with grocery!
          </p>
        </div>

        <div className="relative h-[60vh] md:h-full mt-4  z-50 w-[90vw] md:w-[44vw] rounded-3xl block md:hidden">
          <img
            src={shop}
            alt="shop"
            className="w-full h-[30vh] md:h-full absolute"
          />
          <img
            src={phone}
            alt="phone"
            className="w-[50%] md:w-[40%] h-[35vh] md:h-full absolute right-0"
          />
        </div>

        {/* <div className="relative bg-white mt-6 z-10 w-[90vw] md:w-[44vw] rounded-3xl h-[47vh] md:h-full hidden md:block">
          <p className=" text-black font-semibold text-2xl pt-10 ms-4 w-[60%] md:w-full px-10">
            Abhi <span className="font-bold">Whizz App</span> download kerain
            aur nay waqt ke sath grocery ke shopping ka andaz bhi badalye,
            sahulat our aitamad ke sath bemisaal service ka faida uhtaiye
          </p>
          <div className="flex ms-4 mt-5 justify-center">
            <img
              className="mt-2"
              src={AppStore}
              alt=""
              width="140px"
              height="70px"
            />
            <img
              className="mt-2 ms-3"
              src={PlayStore}
              alt=""
              width="140px"
              height="70px"
            />
          </div>
        </div> */}

        <div className="relative h-[47vh] md:h-full mt-4  z-50 w-[90vw] md:w-[44vw] rounded-3xl hidden md:block">
          <img
            src={shop}
            alt="shop"
            className="w-full h-[30vh] md:h-[55vh] absolute"
          />
          <img
            src={phone}
            alt="phone"
            className="w-[45%] md:w-[45%] h-[35vh] md:h-[55vh] absolute right-0"
          />
        </div>
      </div>
    </div>
  );
};

export default SideView;
