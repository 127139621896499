import SideView from "./components/SideView";
import RightSide from "./components/RightSide";
import logo from "../src/Assets/newlogo.png";
function App() {
  return (
    <div className="bg-[#ffd101] h-auto md:h-[100vh]">
      <div className="flex-col justify-center items-center pt-5 hidden md:flex">
        <div className="flex">
          <img src={logo} alt="Grocery" className="h-[70px] w-[220px]" />
        </div>
        <p className=" text-black font-bold text-sm ms-16">
          It starts with grocery!
        </p>
      </div>
      <div className="flex flex-col md:flex-row md:mt-16">
        <SideView />

        <RightSide />
      </div>
    </div>
  );
}

export default App;
